import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizeService } from './authorize.service';
import { AuthorizeInterceptor } from './authorize.interceptor';
import { AuthRoutes } from './auth.routes';
import { LoginComponent } from './components/login/login.component';
import { SplCoreModule } from 'app/core/core.module';
import { AngularRequestor } from './utils/angular-requestor';
import { FuseSharedModule } from '@fuse/shared.module';
import { FlexModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { PasswordInputModule } from '@common/components/password-input';
import { CodeEntryComponent } from 'app/auth/components/code-entry/code-entry.component';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { BodyModule, GridModule } from '@progress/kendo-angular-grid';
import { FullDateFormatPipe } from '@common/shared/pipes/fullDateFormat.pipe';
import { TwoFactorCodeComponent } from './components/two-factor-code/two-factor-code.component';
import { PhoneConfirmationDialogComponent } from './components/phone-number-confirmation-dialog/phone-number-confirmation-dialog.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    CodeEntryComponent,
    FullDateFormatPipe,
    TwoFactorCodeComponent,
    PhoneConfirmationDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    AuthRoutes,
    SplCoreModule,
    HttpClientModule,
    FuseSharedModule,
    FlexModule,
    NgxMaskModule.forChild(),
    MatRadioModule,
    PasswordInputModule,
    DirectivesModule,
    BodyModule,
    GridModule,
    SplCoreModule,
  ],
  exports: [RouterModule],
  providers: [
    AngularRequestor,
    AuthorizeService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
  ],
})
export class AuthModule {}
