<div id="forgot-password" fxLayout="column">
  <div id="forgot-password-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div class="splash">
    </div>
    <div id="forgot-password-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img src="assets/images/logos/icon.png" alt="logo" />
      </div>

      <!-- <div class="title">LOGIN TO YOUR ACCOUNT</div> -->

      <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="
              !forgotPasswordForm.get('email').hasError('required') &&
              forgotPasswordForm.get('email').hasError('email')
            ">
            There is no user with email address
          </mat-error>
        </mat-form-field>
  
        <div class="button-wrapper">
          <button mat-button (click)="onForgot()" class="submit-button" aria-label="Confirm"
            [disabled]="forgotPasswordForm.invalid || processConfirmation" [ngClass]="{'button-in-progress': processConfirmation}">
            Confirm
          </button>
          <button mat-button (click)="onGoBack()" class="back-action" aria-label="Back"
            [disabled]="processConfirmation" [ngClass]="{'button-in-progress': processConfirmation}">
            Back
          </button>
        </div>
      </form>    

      <!--<div class="login-error">
        <span *ngIf="!!errorMessage" class="error-text">{{errorMessage}}</span>
      </div>-->

    </div>
  </div>
</div>
