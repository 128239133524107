import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AuthClient,
  ForgotPasswordCommand,
} from '@common/services/bo-api-client';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPaths, ReturnUrlType } from 'app/core/app.constants';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  processConfirmation: boolean = false;
  private readonly emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
  ];

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _authClient: AuthClient,
  ) {
    // Configure the layout
    this._fuseConfigService.hideFuseLayout();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', this.emailValidators],
    });
  }

  public get email(): AbstractControl | null {
    return this.forgotPasswordForm.get('email');
  }

  public async onGoBack(): Promise<void> {
    const returnUrl = this.getReturnUrl();
    await this.navigateToReturnUrl(returnUrl);
  }

  public async onForgot(): Promise<void> {
    this.processConfirmation = true;

    const returnUrl = this.getReturnUrl();
    const response = await this._authClient
      .forgotPassword(
        new ForgotPasswordCommand({
          email: this.email.value,
        }),
      )
      .toPromise();

    if (response?.success) {
      //TODO: show alert
      await this.navigateToReturnUrl(returnUrl);
    } else {
      this.email.setErrors({ email: response?.reason });
      this.processConfirmation = false;
    }
    return Promise.resolve();
  }

  private navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath
    );
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
}
