import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as localeEnglish } from 'app/i18n/en';
import { locale as localeSpanish } from 'app/i18n/es';
import { AuthorizeService } from 'app/auth/authorize.service';
import { Observable } from 'rxjs/internal/Observable';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { appConfig } from 'app/core/config/app.config';
import { VersionsComponent } from 'app/core/components/versions/versions.component';
import { loadScript } from '@common/shared/helpers/load-script';
import { environment } from '@common/co/environment';
import { FuseNavigation } from '@fuse/types';
import { AppBusService } from 'app/core/services/app-bus.service';
import * as _ from 'lodash';
import {
  AppSettingsClient,
  AppSettingsResponse,
} from '@common/services/bo-api-client';
import { ILocale } from '@common/i18n/ilocale';

// App.addListener('appStateChange', ({ isActive }) => {
//   console.log('App state changed. Is active?', isActive);
// });

// App.addListener('appUrlOpen', data => {
//   console.log('App opened with URL:', data);
// });

// App.addListener('appRestoredResult', data => {
//   console.log('Restored state:', data);
// });

// const checkAppLaunchUrl = async () => {
//   const { url } = await App.getLaunchUrl();

//   alert('App opened with URL: ' + url);
// };

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('versionDialogTemplate') versionDialogTemplate;
  private readonly keyCodeI: number = 73;

  @HostListener('document:keydown', ['$event'])
  onKeyDownMac(e: KeyboardEvent): void {
    if (e.ctrlKey && (e.metaKey || e.altKey) && e.keyCode === this.keyCodeI) {
      this.openVersionDialog();
    }
  }

  navigation: FuseNavigation[];
  isAuthenticated: Observable<boolean>;
  isInitialized: boolean = false;
  supportedLanguages: ILocale[];
  currentLanguage: ILocale;

  appConfig = appConfig;

  // Private
  private _unsubscribeAll: Subject<void>;
  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {TranslateService} _translateService
   * @param _authorizeService
   * @param _changeDetectorRef
   * @param _appBusService
   * @param dialog
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _authorizeService: AuthorizeService,
    private _appBusService: AppBusService,
    public dialog: MatDialog,
    private _appSettingsClient: AppSettingsClient,
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs([localeEnglish.lang, localeSpanish.lang]);

    // Set the default language
    this._translateService.setDefaultLang(localeEnglish.lang);

    // will be replaced with loader
    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      localeEnglish,
      localeSpanish,
    );

    this.supportedLanguages = [localeEnglish, localeSpanish];

    // Use a language
    this.currentLanguage = localeEnglish;
    this._translateService.use(localeEnglish.lang);

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&callback=Function.prototype`,
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    this._authorizeService.initialized$.subscribe(
      (isInitialized) => (this.isInitialized = isInitialized),
    );

    // the layout appears only when the user is authenticated and receives permissions
    combineLatest(
      this._authorizeService.isAuthenticated(),
      this._appBusService.checkedPermissions$,
    ).subscribe((results) => {
      if (_.every(results, (r) => r === true)) {
        this._fuseConfigService.showFuseLayout();
      }
    });

    this._authorizeService.isAuthenticated().subscribe((value) => {
      if (value) {
        this._appSettingsClient
          .getAppSettings()
          .subscribe((result: AppSettingsResponse) => {
            this._appBusService.setAppSettings(result);
          });
      }
    });

    await this.initializeApp();

    console.log('Register custom capacitor plugins');

    // const accessTokeExpired = await this._authorizeService.isAccessTokenExpired();

    // await this.handleInit(!accessTokeExpired);

    this.isAuthenticated = this._authorizeService.isAuthenticated();

    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        // Boxed
        if (config.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(config.colorTheme);
      });
  }

  private openVersionDialog(): void {
    this.dialog.open(VersionsComponent, { width: '350px' });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public async initializeApp(): Promise<void> {
    console.debug('AppComponent => initializeApp');

    // await this._authorizeService.initialize();

    // const isAuthenticated = await this._authorizeService.isAuthenticated().toPromise();
    // const isTokenExpired = this._authorizeService.isAccessTokenExpired();

    // if (isAuthenticated && !isTokenExpired) {
    //   console.debug('AppComponent => logged in and token not expired');
    // } else if (isAuthenticated && isTokenExpired) {
    //   console.debug('AppComponent => already logged in, expired token');
    //   await this._authorizeService.fetchNewAccessToken();
    // } else {
    //   console.debug('AppCOmponent => not logged in');
    // }
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // /**
  //  * Toggle sidebar open
  //  *
  //  * @param key
  //  */
  // private toggleSidebarOpen(key): void {
  //   this._fuseSidebarService.getSidebar(key).toggleOpen();
  // }

  // private async handleInit(isAuthenticated: boolean): Promise<void> {
  //   if (!isAuthenticated) {
  //     await this._router.navigate(ApplicationPaths.LoginPathComponents);
  //   } else {
  //     await this._router.navigateByUrl('apps/dashboards/analytics', {
  //       replaceUrl: true
  //     });
  //   }
  // }
}
