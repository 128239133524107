export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://spl-services-admin-app-eu-dev.azurewebsites.net',
  identityServerUrl: 'https://spl-services-admin-app-eu-dev.azurewebsites.net',
  appInsights: {
    instrumentationKey: 'b7f20237-f99f-4c56-aae6-4c1766e3a63e',
  },
  verbosityLevel: 0,
  documentsSAConnectionString:
    'BlobEndpoint=https://spltestartifactssadev.blob.core.windows.net/;QueueEndpoint=https://spltestartifactssadev.queue.core.windows.net/;FileEndpoint=https://spltestartifactssadev.file.core.windows.net/;TableEndpoint=https://spltestartifactssadev.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2026-09-19T21:10:44Z&st=2023-09-19T13:10:44Z&spr=https&sig=2irQ5HxNCpslMoEMI%2BfhhsAfSWTuavLyki5m04uIU0g%3D',
  spltempsaConnectionString:
    'BlobEndpoint=https://spltempsa.blob.core.windows.net/;QueueEndpoint=https://spltempsa.queue.core.windows.net/;FileEndpoint=https://spltempsa.file.core.windows.net/;TableEndpoint=https://spltempsa.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-10-01T17:16:33Z&st=2023-10-18T09:16:33Z&spr=https&sig=2a24EX8ItMuk7SGNQ%2BGLnKuTm9pW2pwxYRTVp4Ui7U0%3D',
  googleMapsAPIKey: 'AIzaSyBlHdxloSPK6tnizvYU_HSif4LoSVTamrE',
  bitmovinPlayerKey: 'b3696cbe-454f-4a3f-906e-b08e24005726',
};
