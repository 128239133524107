<div id="reset-password" fxLayout="column">
  <div id="reset-password-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div class="splash">
    </div>
    <div id="reset-password-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img src="assets/images/logos/icon.png" alt="logo" />
      </div>

      <!-- <div class="title">LOGIN TO YOUR ACCOUNT</div> -->
      <ng-container *ngIf="!displayBackButton">
      <form name="reset" [formGroup]="resetPasswordForm" novalidate>
        <mat-form-field appearance="outline" hidden>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error
              *ngIf="email.hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
              Please enter a valid email address
            </mat-error>
        </mat-form-field>

        <app-password-input [control]="password" [label]="'Password'"></app-password-input>  
        <app-password-input [control]="passwordConfirm" [label]="'Confirm Password'"></app-password-input>
  
        <div class="button-wrapper">
          <button mat-button (click)="onReset()" class="submit-button" aria-label="Confirm"
            [disabled]="resetPasswordForm.invalid || onload" [ngClass]="{'button-in-progress': onload}">
            {{ onload ? 'Confirmation...' : 'Confirm' }}
          </button>
        </div>
      </form>
      </ng-container>

      <div class='error-code' *ngIf="displayBackButton">
        <div class="spl-info-text mb-32">
          Reset password request URL you are trying to use has already been used and can't be used again.
        </div>

        <button
          mat-button
          class="submit-button"
          color="accent"
          aria-label="Back"
          [routerLink]="'/login'"
        >
          Back
        </button>
      </div>

    </div>
  </div>
</div>
