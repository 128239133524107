import { IBoLocale } from './ibo-locale';

export const locale: IBoLocale = {
  lang: 'us',
  code: 'US',
  humanTitle: 'English',
  data: {
    COMMON: {
      EMAIL: 'Email',
      YOUR_EMAIL: 'Your Email',
      EMAIL_INVALID: 'Email is invalid',
      EMAIL_REQUIRED: 'Email is required',
      PHONE: 'Phone',
      YOUR_PHONE: 'Your Phone',
      PHONE_INVALID: 'Phone Invalid',
      PHONE_REQUIRED: 'Phone Required',
      UNIQUE_ID: 'Unique Id',
      UNIQUE_ID_INVALID: 'Invalid Unique Id',
      UNIQUE_ID_REQUIRED: 'Unique Id is required',
      PASSWORD: 'Password',
      YOUR_PASSWORD: 'Your Password',
      PASSWORD_REQUIRED: 'Password required',
      PASSWORD_MUST_MATCH: 'Passwords must match',
      HERE: 'here',
      YOUR_LANGUAGE: 'Your Language',
      PERSONAL_INFO: {
        NAME: 'Name',
        NAME_REQUIRED: 'Name is required',
        NAME_INVALID: 'Please enter a valid Name',
        YOUR_PERSONAL_INFO: 'Your Personal Info',
        YOUR_DATE_OF_BIRTH: 'Your Date Of Birth',
        DATE_OF_BIRTH_REQUIRED: 'Date Of Birth is required',
        DATE_OF_BIRTH_INVALID: 'Please enter a valid Date Of Birth',
        DATE_OF_BIRTH_MINIMAL: 'Minimal age is 18 years',
        YOUR_FIRST_NAME: 'Your First Name',
        FIRST_NAME_REQUIRED: 'First Name is required',
        FIRST_NAME_INVALID: 'Please enter a valid First Name',
        YOUR_LAST_NAME: 'Your Last Name',
        LAST_NAME_REQUIRED: 'Last Name is required',
        LAST_NAME_INVALID: 'Please enter a valid Last Name',
        ZIP_CODE: 'Zip Code',
        ZIP_CODE_REQUIRED: 'Zip Code is required',
      },
      ACTIONS: {
        CONTINUE: 'Continue',
        GO_BACK_A_STEP: 'Go Back A Step',
        GO_BACK: 'Go Back',
        BACK: 'Back',
        GO_BACK_TO_LOGIN: 'Go back to login',
        GO_TO_SIGNIN: 'Go To Sign-In',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        ACKNOWLEDGE: 'Acknowledge',
        SIGN_OUT: 'Sign Out',
        REMOVE: 'Remove',
        START: 'Start',
        STOP: 'Stop',
        SKIP: 'Skip',
        EDIT: 'Edit',
      },
      STATUSES: {
        SUCCSESS: 'Success!',
        FAILURE: 'Failure!',
      },
      TIME: {
        WEEK: 'Week',
        DAY: 'Day',
        DATE: 'Date',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow',
        MON_TEXT: 'Monday',
        TUE_TEXT: 'Tuesday',
        WEN_TEXT: 'Wednesday',
        THU_TEXT: 'Thursday',
        FRI_TEXT: 'Friday',
        SAT_TEXT: 'Satruday',
        SAN_TEXT: 'Sunday',
      },
      DOMAIN_COMMON: {
        IN_STUDIO: 'In-Clinic',
        OUT_OF_STUDIO: 'At Home',
        SCHEDULE: 'Schedule',
        OVERVIEW: 'Overview',
        CLASS: 'Class',
        LOCATION: 'Location',
        STATE: 'State',
        TRAINER: 'Trainer',
        STATION: 'Station',
      },
    },
    DIALOGS: {
      WARNING_TITLE: 'Warning',
      DELETE_ACC_TITLE1: 'Account',
      DELETE_ACC_TITLE2: 'will be deleted',
      DELETE_ACC_DESC: 'Do you want to continue?',
      ATTEMPT_START_NEW_LVL:
        'Wait. You are attempting to start a new level of your program without completing the previous level. We recommend you go back and complete training day: {replace1} prior to completing training day: (Week {replace2} Day {replace3})',
      ATTEMPT_START_NO_REST:
        'Wait. You are attempting to complete another training day without adequate rest and recovery. We recommend 24-48 hours in between training days to optimize system healing and regeneration.',
    },
  },
};
