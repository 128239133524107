import { IBoLocale } from './ibo-locale';

export const locale: IBoLocale = {
  lang: 'es',
  code: 'ES',
  humanTitle: 'Español',
  data: {
    COMMON: {
      EMAIL: 'Correo Electrónico',
      YOUR_EMAIL: 'Tu dirección de correo electrónico',
      EMAIL_INVALID: 'Email no es válido',
      EMAIL_REQUIRED: 'Email obligatorio',
      PHONE: 'Teléfono',
      YOUR_PHONE: 'Teléfono',
      PHONE_INVALID: 'Teléfono no válido',
      PHONE_REQUIRED: 'Teléfono obligatorio',
      UNIQUE_ID: 'Identificador único.',
      UNIQUE_ID_INVALID: 'Id Único no válido',
      UNIQUE_ID_REQUIRED: 'El identificador único es obligatorio',
      PASSWORD: 'Contraseña',
      YOUR_PASSWORD: 'Su Contraseña',
      PASSWORD_REQUIRED: 'Contraseña obligatoria',
      PASSWORD_MUST_MATCH: 'Las contraseñas deben coincidir',
      HERE: 'aquí',
      YOUR_LANGUAGE: 'Su Idioma',
      PERSONAL_INFO: {
        YOUR_PERSONAL_INFO: 'Sus datos personales',
        YOUR_DATE_OF_BIRTH: 'Tu fecha de nacimiento',
        DATE_OF_BIRTH_REQUIRED: 'La fecha de nacimiento es obligatoria',
        DATE_OF_BIRTH_INVALID: 'Ingrese una Fecha De Nacimiento válida',
        DATE_OF_BIRTH_MINIMAL: 'La edad mínima es de 18 años',
        YOUR_FIRST_NAME: 'Tu nombre',
        FIRST_NAME_REQUIRED: 'El nombre es obligatorio',
        FIRST_NAME_INVALID: 'Por favor, introduzca un Nombre válido',
        YOUR_LAST_NAME: 'Tu Apellido',
        LAST_NAME_REQUIRED: 'Apellido obligatorio',
        LAST_NAME_INVALID: 'Por favor, introduzca un Apellido válido',
        ZIP_CODE: 'Código Postal',
        ZIP_CODE_REQUIRED: 'Se requiere código postal',
        NAME: 'Nombre',
        NAME_REQUIRED: 'Nombre obligatorio',
        NAME_INVALID: 'Por favor, introduzca un nombre válido',
      },
      TIME: {
        WEEK: 'Semana',
        DAY: 'Día',
        DATE: 'Fecha',
        TODAY: 'Hoy en día',
        TOMORROW: 'Mañana',
        MON_TEXT: 'Lunes',
        TUE_TEXT: 'Martes',
        WEN_TEXT: 'Miércoles',
        THU_TEXT: 'Jueves',
        FRI_TEXT: 'Viernes',
        SAT_TEXT: 'Sábado',
        SAN_TEXT: 'Domingo',
      },
      ACTIONS: {
        CONTINUE: 'Continuar',
        GO_BACK_A_STEP: 'Retroceder un paso',
        GO_BACK: 'Volver atrás',
        BACK: 'Atrás',
        GO_BACK_TO_LOGIN: 'Volver al inicio de sesión',
        GO_TO_SIGNIN: 'Ir A Inicio De Sesión',
        SAVE: 'Guardar',
        CANCEL: 'Cancelar',
        ACKNOWLEDGE: 'Reconocer',
        SIGN_OUT: 'Cerrar sesión',
        REMOVE: 'Eliminar',
        START: 'Comenzar',
        STOP: 'Detener',
        SKIP: 'Saltar',
        EDIT: 'Editar',
      },
      STATUSES: {
        SUCCSESS: '¡Éxito!',
        FAILURE: '¡Fracaso!',
      },
      DOMAIN_COMMON: {
        IN_STUDIO: 'En la clínica',
        OUT_OF_STUDIO: 'En casa',
        SCHEDULE: 'Horario',
        OVERVIEW: 'Visión general',
        CLASS: 'Clase',
        STATE: 'Estado',
        LOCATION: 'Ubicación',
        TRAINER: 'Entrenador',
        STATION: 'Estación',
      },
    },
    DIALOGS: {
      WARNING_TITLE: 'Advertencia',
      DELETE_ACC_TITLE1: 'La cuenta',
      DELETE_ACC_TITLE2: 'será eliminada',
      DELETE_ACC_DESC: '¿Quieres continuar?',
      ATTEMPT_START_NEW_LVL:
        'Espere. Está intentando iniciar un nuevo nivel de su programa sin haber completado el nivel anterior. Te recomendamos que vuelvas atrás y completes el día de entrenamiento: {replace1}  antes de completar el día de entrenamiento: (Semana {replace2} Día {replace3})',
      ATTEMPT_START_NO_REST:
        'Espera. Estás intentando completar otro día de entrenamiento sin el descanso y la recuperación adecuados. Recomendamos 24-48 horas entre días de entrenamiento para optimizar la curación y regeneración del sistema.',
    },
  },
};
