import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AuthClient,
  ResetPasswordCommand,
  VerifyPasswordTokenCommand,
} from '@common/services/bo-api-client';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPaths, ReturnUrlType } from 'app/core/app.constants';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  protected resetPasswordForm: FormGroup;
  protected onload: boolean = false;
  private code: string;
  private emailValue: string;
  protected displayBackButton: boolean = false;
  private _unsubscribeAll: Subject<void>;
  private readonly emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
  ];
  private readonly passwordValidators: Validators[] = [Validators.required];
  private readonly passwordConfirmValidators: Validators[] = [
    Validators.required,
    confirmPasswordValidator,
  ];

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _authClient: AuthClient,
  ) {
    // Configure the layout
    this._fuseConfigService.hideFuseLayout();
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      email: ['', this.emailValidators],
      password: ['', this.passwordValidators],
      passwordConfirm: ['', this.passwordConfirmValidators],
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.password.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        this.passwordConfirm.updateValueAndValidity.bind(this.passwordConfirm),
      );
    this.code = atob(this._route.snapshot.queryParamMap.get('code'));
    this.emailValue = this._route.snapshot.queryParamMap.get('email');
    if (this.emailValue) {
      this.email.setValue(this.emailValue);
      this.email.disable({ onlySelf: true });
      this.checkCode();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public get email(): AbstractControl | null {
    return this.resetPasswordForm.get('email');
  }

  public get password(): AbstractControl | null {
    return this.resetPasswordForm.get('password');
  }

  public get passwordConfirm(): AbstractControl | null {
    return this.resetPasswordForm.get('passwordConfirm');
  }

  public async onGoBack(): Promise<void> {
    const returnUrl = this.getReturnUrl();
    await this.navigateToReturnUrl(returnUrl);
  }

  public async onReset(): Promise<void> {
    const returnUrl = this.getReturnUrl();
    try {
      const response = await this._authClient
        .resetPassword(
          new ResetPasswordCommand({
            email: this.email.value,
            password: this.password.value,
            confirmPassword: this.passwordConfirm.value,
            code: this.code,
          }),
        )
        .toPromise();
      if (response.success) {
        await this.navigateToReturnUrl(returnUrl);
      } else {
        this.password.setErrors({ backend: response?.reason });
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  private checkCode(): void {
    const verifyPasswordTokenCommand = new VerifyPasswordTokenCommand({
      passwordToken: this.code,
      email: this.email.value,
    });
    this.onload = true;
    this._authClient.verifyPasswordToken(verifyPasswordTokenCommand).subscribe(
      (res) => {
        if (res.success === false) {
          this.displayBackButton = true;
        }
        this.onload = false;
      },
      (error) => {
        this.onload = false;
        throw error;
      },
    );
  }

  private navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath
    );
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

interface INavigationState {
  [ReturnUrlType]: string;
}
